import React, { useState } from "react"
import PropTypes from "prop-types"
import Button from "../ui/Button"
import Cushion from "../ui/Cushion"
import Grid from "../ui/Grid"
import GridItem from "../ui/GridItem"
import MaxWidth from "../ui/MaxWidth"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"

const FormSection = ({
  validate,
  values,
  visible,
  description,
  question,
  renderAnswers,
  onBack,
  onNext,
  submit,
}) => {
  const [hasSubmitted, setHasSubmitted] = useState(false)
  const errors = typeof validate === "function" ? validate(values) : []
  console.log("errors", errors)

  const validator = (func) => {
    setHasSubmitted(true)
    if (errors.length > 0) {
      // has errors, do not go to the next page
      return null
    }

    return func()
  }

  return (
    <Rectangle
      fill="white"
      radius="rounded"
      style={{
        position: visible ? "static" : "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        visibility: visible ? "visible" : "hidden",
        zIndex: 2,
        boxSizing: "border-box",
      }}
    >
      <Cushion all="large" component="div">
        <Cushion bottom="xsmall">
          <Typography component="div" preset="subheading" align="center">
            {question}
          </Typography>
        </Cushion>
        <Cushion bottom="large">
          <Typography component="div" preset="body" align="center">
            {description}
          </Typography>
        </Cushion>
        {renderAnswers()}
        {hasSubmitted && errors.length > 0 && (
          <ul>
            {errors.map(error => (
              <Typography component="li" preset="error">
                {error}
              </Typography>
            ))}
          </ul>
        )}
        <Cushion top="medium">
          <Grid
            columns="1fr"
            rowGap="small"
            areas={`"forwards" "backwards"`}
            responsive={[
              {
                minWidth: "tab",
                props: {
                  columns: "repeat(2, 1fr)",
                  columnGap: "medium",
                  alignItems: "center",
                  areas: `"backwards forwards"`,
                },
              },
            ]}
          >
            <GridItem
              component="div"
              justifySelf="stretch"
              area="backwards"
              style={{ width: "100%" }}
            >
              {onBack && (
                <MaxWidth
                  component="div"
                  maxWidth={300}
                  center
                  responsive={[
                    {
                      minWidth: "tab",
                      props: { maxWidth: 180, center: false, left: true },
                    },
                  ]}
                >
                  <Typography
                    component="div"
                    align="center"
                    responsive={[{ minWidth: "tab", props: { align: "left" } }]}
                  >
                    <Button
                      fullWidth
                      theme="grey"
                      type="button"
                      onClick={onBack}
                    >
                      Back
                    </Button>
                  </Typography>
                </MaxWidth>
              )}
            </GridItem>
            <GridItem component="div" justifySelf="stretch" area="forwards">
              {onNext && (
                <MaxWidth
                  maxWidth={300}
                  component="div"
                  center
                  responsive={[
                    {
                      minWidth: "tab",
                      props: { maxWidth: 180, center: false, right: true },
                    },
                  ]}
                >
                  <Button
                    theme="blue"
                    fullWidth
                    type="button"
                    onClick={() => {
                      validator(onNext)
                    }}
                  >
                    Next
                  </Button>
                </MaxWidth>
              )}
              {submit && typeof submit === 'function' && (
                <MaxWidth
                  maxWidth={300}
                  component="div"
                  center
                  responsive={[
                    {
                      minWidth: "tab",
                      props: { maxWidth: 180, center: false, right: true },
                    },
                  ]}
                >
                  <Button theme="red" fullWidth type="submit" onClick={e => {
                    e.preventDefault()
                    validator(submit)
                  }}>
                    Send
                  </Button>
                </MaxWidth>
              )}
            </GridItem>
          </Grid>
        </Cushion>
      </Cushion>
    </Rectangle>
  )
}

FormSection.propTypes = {
  description: PropTypes.string.isRequired,
  question: PropTypes.string.isRequired,
  renderAnswers: PropTypes.func.isRequired,
}
export default FormSection
