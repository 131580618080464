import React from "react"
import PropTypes from "prop-types"
import ReCAPTCHA from "react-google-recaptcha"
import FormSection from "./FormSection"
import Input from "../Input"
import Cushion from "../ui/Cushion"

const fields = [
  {
    id: "name",
    name: "name",
    label: "Name",
    placeholder: "Joe Bloggs",
  },
  {
    id: "email",
    name: "email",
    label: "Email Address",
    placeholder: "Joe@bloggs.com",
  },
  {
    id: "telephone",
    name: "telephone",
    label: "Telephone Number",
    placeholder: "07123456789",
  },
  {
    id: "address",
    name: "address",
    label: "Address",
    placeholder: "1 Flush Street",
  },
  {
    id: "postcode",
    name: "postcode",
    label: "Post Code",
    placeholder: "AB1 2CD",
  },
]

function validate(values) {
  console.log("values", values)
  const errors = []

  if (!values.contactDetails.name) {
    errors.push("Please provide your name.")
  }

  if (!values.contactDetails.email) {
    errors.push("Please provide your email address.")
  }

  if (!values.contactDetails.telephone) {
    errors.push("Please provide your telephone number.")
  }

  if (!values.contactDetails.address) {
    errors.push("Please provide your address.")
  }

  if (!values.contactDetails.postcode) {
    errors.push("Please provide your postcode.")
  }

  if (!values.contactDetails.recaptcha) {
    errors.push("Please verify you are not a robot.")
  }

  return errors
}

const Contact = ({ form, setForm, submit, onBack, onNext, visible }) => {
  const formContactDetails = form.values.contactDetails || {}

  return (
    <FormSection
      question="Contact details"
      description="Please fill in your contact details so we can book your appointment"
      renderAnswers={() => (
        <div>
          {fields.map(field => (
            <Cushion component="div" bottom="medium">
              <Input
                key={field.id}
                id={field.id}
                label={field.label}
                input={{
                  ...field,
                  value: formContactDetails[field.id],
                  required: true,
                }}
                onChange={e => {
                  setForm({
                    ...form,
                    values: {
                      ...form.values,
                      contactDetails: {
                        ...formContactDetails,
                        [field.id]: e.target.value,
                      },
                    },
                  })
                }}
              />
            </Cushion>
          ))}
          <Cushion component="div">
            <ReCAPTCHA
              sitekey="6Lf5ROAZAAAAAJJbcOJxSWGN_KnKVTRXJMIwhUga"
              onChange={value => {
                setForm({
                  ...form,
                  values: {
                    ...form.values,
                    contactDetails: {
                      ...formContactDetails,
                      recaptcha: value,
                    },
                  },
                })
              }}
            />
          </Cushion>
        </div>
      )}
      submit={submit}
      onBack={onBack}
      onNext={onNext}
      visible={visible}
      values={form.values}
      validate={validate}
    />
  )
}

export default Contact

// <div class="g-recaptcha" data-sitekey="6Lf5ROAZAAAAAJJbcOJxSWGN_KnKVTRXJMIwhUga"></div>
