import React from "react"
import PropTypes from "prop-types"
import Cushion from "../ui/Cushion"
import Typography from "../ui/Typography"
import InputComponent from "../ui/Input"

const Input = ({ id, input, label, onChange }) => {
  return (
    <Cushion component="label" bottom="medium">
      <Cushion bottom="xsmall">
        <Typography
          component="div"
          preset="label"
          color="medGrey"
          htmlFor="name"
          style={{ display: "inline-block" }}
        >
          {label}
        </Typography>
      </Cushion>
      <InputComponent id={id} onChange={onChange} {...input} />
    </Cushion>
  )
}

Input.propTypes = {
  id: PropTypes.string.isRequired,
  input: PropTypes.shape({}).isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
}

export default Input
