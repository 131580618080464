import React, { useState, useRef } from "react"
import Frame from "../components/Frame"
import SEO from "../components/seo"
import ThermalBackground from "../components/ThermalBackground"
import Cushion from "../components/ui/Cushion"
import Typography from "../components/ui/Typography"
import ConcaveBottom from "../components/ConcaveBottom"
import MaxWidth from "../components/ui/MaxWidth"
import Space from "../components/ui/Space"
import Boiler from "../components/CommercialBookingForm/Boiler"
import BoilerCount from "../components/CommercialBookingForm/BoilerCount"
import ContactDetails from "../components/BookingForm/ContactDetails"
import PremisesType from "../components/CommercialBookingForm/PremisesType"

const Commercial = ({ location, data }) => {
  const {
    commercialJson: { title, summary },
    contactDetailsJson,
  } = data

  const [step, setStep] = useState("premisesType")
  const [form, setForm] = useState({
    values: {
      premisesType: null,
      boilerType: null,
      boilerCount: null,
      contactDetails: {},
    },
  })

  const formRef = useRef()
  return (
    <Frame
      currentPathname={location.pathname}
      footerBackgroundColor="vLightGrey"
      contactDetails={contactDetailsJson}
    >
      <SEO title="Commercial" />
      <ThermalBackground component="div">
        <ConcaveBottom fill="vLightGrey">
          <MaxWidth maxWidth={800} center>
            <Cushion
              component="div"
              horizontal="small"
              top="xxlarge"
              bottom="xxxxlarge"
              responsive={[
                { minWidth: "palm", props: { horizontal: "medium" } },
                { minWidth: "tab", props: { horizontal: "xlarge" } },
              ]}
            >
              <Typography component="div" align="center">
                <Cushion bottom="large">
                  <Typography component="div" preset="hero" color="white">
                    {title}
                  </Typography>
                  <Cushion top="medium">
                    <Typography component="div" preset="body" color="white">
                      {summary}
                    </Typography>
                  </Cushion>
                </Cushion>
              </Typography>
            </Cushion>
          </MaxWidth>
        </ConcaveBottom>
      </ThermalBackground>
      <Space top={-150}>
        <MaxWidth maxWidth={900} center>
          <Cushion component="div" horizontal="medium" bottom="xxlarge">
            <form
              netlify
              name="commercialBookingForm"
              method="post"
              data-netlify="true"
              data-netlify-honeypot="bot-field"
              data-netlify-recaptcha="true"
              ref={formRef}
              style={{ position: "relative", zIndex: 3 }}
            >
              <input hidden name="form-name" value="commercialBookingForm" />
              <PremisesType
                visible={step === "premisesType"}
                form={form}
                setForm={setForm}
                onNext={() => {
                  window.scrollTo(0, 0)
                  setStep("boiler")
                }}
              />
              <Boiler
                visible={step === "boiler"}
                form={form}
                setForm={setForm}
                onNext={() => {
                  window.scrollTo(0, 0)
                  setStep("boilerCount")
                }}
                onBack={() => {
                  window.scrollTo(0, 0)
                  setStep("premisesType")
                }}
              />
              <BoilerCount
                visible={step === "boilerCount"}
                form={form}
                setForm={setForm}
                onNext={() => {
                  window.scrollTo(0, 0)
                  setStep("contact")
                }}
                onBack={() => {
                  window.scrollTo(0, 0)
                  setStep("boiler")
                }}
              />
              <ContactDetails
                submit={() => {
                  formRef.current.submit()
                }}
                visible={step === "contact"}
                form={form}
                setForm={setForm}
                onBack={() => {
                  window.scrollTo(0, 0)
                  setStep("boilerCount")
                }}
              />
            </form>
          </Cushion>
        </MaxWidth>
      </Space>
    </Frame>
  )
}

export default Commercial

export const query = graphql`
  {
    contactDetailsJson {
      email
      mobile
      landline
    }
    commercialJson {
      title
      summary
    }
  }
`
