import React from "react"
import PropTypes from "prop-types"
import Cushion from "../ui/Cushion"
import Rectangle from "../ui/Rectangle"
import Typography from "../ui/Typography"

const SelectBox = ({
  checked,
  children,
  id,
  input,
  onChange,
  type,
  style,
  ...otherProps
}) => {
  const inputOptions = { ...input, id }

  if (typeof checked === "boolean") {
    inputOptions.checked = checked
  }

  if (typeof onChange === "function") {
    inputOptions.onChange = onChange
  }

  return (
    <label>
      <Rectangle
        fill="white"
        radius="rounded"
        border={checked ? "solid 5px #FF7373" : "solid 5px #D8D8D8"}
      >
        <Cushion vertical="medium" horizontal="large">
          <Typography
            component="div"
            preset="body"
            color={checked ? "#FF7373" : "#999"}
            style={style}
          >
            {children}
          </Typography>
        </Cushion>
      </Rectangle>
      <input
        type={type}
        {...inputOptions}
        {...otherProps}
        style={{
          position: "relative",
          visibility: "hidden",
          width: 0,
          height: 0,
        }}
      />
    </label>
  )
}

SelectBox.propTypes = {
  checked: PropTypes.bool,
  input: PropTypes.object,
  onChange: PropTypes.func,
  type: PropTypes.oneOf(["checkbox", "radio"]).isRequired,
  style: PropTypes.shape({}),
  id: PropTypes.string.isRequired,
}

SelectBox.defaultProps = {
  onChange: null,
  checked: undefined,
  input: undefined,
  style: {},
}
export default SelectBox
