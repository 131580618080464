import React from "react"
import PropTypes from "prop-types"
import FormSection from "../BookingForm/FormSection"
import Typography from "../ui/Typography"
import SelectBox from "../SelectBox"

const BoilerCountOptions = ["1", "2", "3", "4+"]

function validate(values) {
  const errors = []

  if (!values.boilerCount) {
    errors.push("Please let us know how many boilers you have.")
  }

  return errors
}

const BoilerCount = ({ form, setForm, visible, isEnd, onBack, onNext }) => {
  const formBoilerCount = form.values.boilerCount || null

  return (
    <FormSection
      question="How many boilers do you have?"
      description="Select the amount of boilers that are in your premises."
      renderAnswers={() => (
        <Typography component="div" align="center">
          {BoilerCountOptions.map(value => {
            const checked = value === formBoilerCount
            return (
              <SelectBox
                id={`boiler_count_${value}`}
                type="radio"
                checked={checked}
                onChange={() => {
                  setForm({
                    ...form,
                    values: { ...form.values, boilerCount: value },
                  })
                }}
                input={{ value, name: "boiler-count" }}
                style={{
                  display: "inline-block",
                  padding: 0,
                  position: "relative",
                  height: 45,
                  width: 45,
                  lineHeight: "0px",
                  borderRadius: "50%",
                  borderWidth: 3,
                  marginLeft: 6,
                  marginRight: 6,
                }}
              >
                <span
                  style={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%)",
                  }}
                >
                  {value}
                </span>
              </SelectBox>
            )
          })}
        </Typography>
      )}
      isEnd={isEnd}
      onBack={onBack}
      values={form.values}
      onNext={onNext}
      validate={validate}
      visible={visible}
    />
  )
}

export default BoilerCount
