import React from "react"
import FormSection from "../BookingForm/FormSection"
import Cushion from "../ui/Cushion"
import Grid from "../ui/Grid"
import Typography from "../ui/Typography"
import SelectBox from "../SelectBox"

const boilerTypes = [
  {
    id: "natural-gas",
    value: "Natural Gas",
  },
  {
    id: "bio-mass",
    value: "Bio Mass",
  },
  {
    id: "oil",
    value: "Oil",
  },
  {
    id: "air-source",
    value: "Air Source",
  },
  {
    id: "ground-source",
    value: "Ground Source",
  },
]

function validate(values) {
  const errors = []

  if (!values.boilerType) {
    errors.push("Please select which type of boiler you have")
  }

  return errors
}

const Boiler = ({ form, setForm, isEnd, onBack, onNext, visible }) => {
  const boilerType = form.values.boilerType

  return (
    <FormSection
      question="What type of boiler do you have?"
      description="Select the type of boiler that applies to your premises"
      isEnd={isEnd}
      onBack={onBack}
      onNext={onNext}
      validate={validate}
      visible={visible}
      values={form.values}
      renderAnswers={() => (
        <Grid
          component="div"
          columns="1fr"
          responsive={[
            {
              minWidth: 900,
              props: {
                columns: "repeat(2, 1fr)",
                columnGap: "medium",
                rowGap: "medium",
              },
            },
          ]}
        >
          {boilerTypes.map(({ id, value }) => {
            const checked = boilerType === value
            return (
              <p>
                <SelectBox
                  id={id}
                  type="radio"
                  checked={checked}
                  onChange={() => {
                    setForm({
                      ...form,
                      values: { ...form.values, boilerType: value },
                    })
                  }}
                  input={{ value, name: "boilerType" }}
                >
                  <Typography component="div" align="center">
                    {value}
                  </Typography>
                </SelectBox>
              </p>
            )
          })}
        </Grid>
      )}
    />
  )
}

export default Boiler
