import styled from 'styled-components';
import PropTypes from 'prop-types';
import { styledComponentsResponsiveStyles } from '../utils/responsive';
import renderByCloning from '../utils/renderByCloning';
import withTheme from '../Theme/withTheme';
import spacePropsToCss from './props-to-css';

const Space = ({
  all,
  bottom,
  children,
  component,
  horizontal,
  left,
  responsive,
  right,
  top,
  uiTheme,
  vertical,
  ...otherProps
}) => {
  return renderByCloning(component, children, { ...otherProps });
};

Space.propTypes = {
  all: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  bottom: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  children: PropTypes.node,
  component: PropTypes.string,
  horizontal: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  left: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  responsive: PropTypes.array,
  right: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.object,
  top: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  uiTheme: PropTypes.object,
  vertical: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

Space.defaultProps = {
  all: 0,
  bottom: null,
  children: null,
  component: null,
  horizontal: null,
  left: null,
  responsive: [],
  right: null,
  style: {},
  top: null,
  uiTheme: {},
  vertical: null,
};

const StyledSpace = styled(Space)`
  ${props => spacePropsToCss(props)}
  ${props => {
    const { responsive, uiTheme } = props;
    return styledComponentsResponsiveStyles(uiTheme, responsive, breakpointProps =>
      spacePropsToCss({
        uiTheme,
        ...breakpointProps,
      }),
    );
  }}
`;

export default withTheme(StyledSpace);
