import { getSpacing } from '../Theme/selectors';
import { convertStyleValue } from '../utils/js-style-to-css';

const spacePropsToCss = ({ uiTheme, all, horizontal, vertical, top, right, bottom, left }) => {
  return `
  ${
    top || vertical || all
      ? `margin-top: ${convertStyleValue(
          getSpacing(uiTheme, top) || getSpacing(uiTheme, vertical) || getSpacing(uiTheme, all),
          'margin',
        )};`
      : ''
  }
  ${
    right || horizontal || all
      ? `margin-right: ${convertStyleValue(
          getSpacing(uiTheme, right) || getSpacing(uiTheme, horizontal) || getSpacing(uiTheme, all),
          'margin',
        )};`
      : ''
  }
  ${
    bottom || vertical || all
      ? `margin-bottom: ${convertStyleValue(
          getSpacing(uiTheme, bottom) || getSpacing(uiTheme, vertical) || getSpacing(uiTheme, all),
          'margin',
        )};`
      : ''
  }
  ${
    left || horizontal || all
      ? `margin-left: ${convertStyleValue(
          getSpacing(uiTheme, left) || getSpacing(uiTheme, horizontal) || getSpacing(uiTheme, all),
          'margin',
        )};`
      : ''
  }
`;
};

export default spacePropsToCss;
