const gridItemPropsToCss = ({
  alignSelf,
  area,
  columnEnd,
  columnStart,
  justifySelf,
  rowEnd,
  rowStart,
}) => `
  ${alignSelf ? `align-self: ${alignSelf};` : ''}
  ${area ? `grid-area: ${area};` : ''}
  ${columnEnd ? `grid-column-end: ${columnEnd};` : ''}
  ${columnStart ? `grid-column-start: ${columnStart};` : ''}
  ${rowEnd ? `grid-row-end: ${rowEnd};` : ''}
  ${rowStart ? `grid-row-start: ${rowStart};` : ''}
  ${justifySelf ? `justify-self: ${justifySelf};` : ''}
`;

export default gridItemPropsToCss;
