import { getSpacing } from '../Theme/selectors';
import { convertStyleValue } from '../utils/js-style-to-css';

const gridPropsToCss = ({
  uiTheme,
  alignContent,
  alignItems,
  display,
  columnGap,
  areas,
  columns,
  rows,
  rowGap,
  justifyContent,
  justifyItems,
}) => `
  ${display ? `display: ${display};` : ''}
  ${areas ? `grid-template-areas: ${areas};` : ''}
  ${columns ? `grid-template-columns: ${columns};` : ''}
  ${
    columnGap
      ? `grid-column-gap: ${convertStyleValue(getSpacing(uiTheme, columnGap), 'gridColumnGap')};`
      : ''
  }
  ${rows ? `grid-template-rows: ${rows};` : ''}
  ${rowGap ? `grid-row-gap: ${convertStyleValue(getSpacing(uiTheme, rowGap), 'gridRowGap')};` : ''}
  ${alignContent ? `align-content: ${alignContent};` : ''}
  ${alignItems ? `align-items: ${alignItems}` : ''}
  ${justifyContent ? `justify-content: ${justifyContent};` : ''}
  ${justifyItems ? `justify-items: ${justifyItems};` : ''}
`;

export default gridPropsToCss;
