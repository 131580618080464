import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { styledComponentsResponsiveStyles } from '../utils/responsive';
import { getPreset } from '../Theme/selectors';
import withTheme from '../Theme/withTheme';
import gridPropsToCss from './props-to-css';

const Grid = ({
  alignContent,
  alignItems,
  areas,
  columnGap,
  columns,
  component: Component,
  className = '',
  display,
  justifyContent,
  justifyItems,
  preset: presetName,
  responsive,
  rowGap,
  rows,
  uiTheme,
  ...otherProps
}) => {
  const preset = presetName ? getPreset(uiTheme, 'grid', presetName) : {};
  const { className: presetClassName = '' } = preset;
  return <Component className={`${className} ${presetClassName}`} {...otherProps} />;
};

Grid.propTypes = {
  alignContent: PropTypes.string,
  alignItems: PropTypes.string,
  areas: PropTypes.string,
  children: PropTypes.any.isRequired,
  className: PropTypes.string,
  columnGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columns: PropTypes.string,
  component: PropTypes.string,
  display: PropTypes.string,
  justifyContent: PropTypes.string,
  justifyItems: PropTypes.string,
  preset: PropTypes.string,
  responsive: PropTypes.array,
  rowGap: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rows: PropTypes.string,
  uiTheme: PropTypes.object,
};

Grid.defaultProps = {
  alignContent: null,
  alignItems: null,
  areas: null,
  className: null,
  columnGap: null,
  columns: null,
  component: 'div',
  display: null,
  justifyContent: null,
  justifyItems: null,
  preset: null,
  responsive: [],
  rowGap: null,
  rows: null,
  uiTheme: null,
};

const StyledGrid = styled(Grid)`
  ${props => {
    const { preset: presetName, uiTheme } = props;
    const preset = getPreset(uiTheme, 'grid', presetName);
    return gridPropsToCss({ display: 'grid', ...preset, ...props });
  }}
  ${props => {
    const { preset, uiTheme } = props;
    const { responsive: presetResponsive } = getPreset(uiTheme, 'grid', preset);
    return styledComponentsResponsiveStyles(uiTheme, presetResponsive, breakpointProps =>
      gridPropsToCss({
        uiTheme,
        ...breakpointProps,
      }),
    );
  }}
  ${props => {
    const { responsive, uiTheme } = props;
    return styledComponentsResponsiveStyles(uiTheme, responsive, breakpointProps =>
      gridPropsToCss({
        uiTheme,
        ...breakpointProps,
      }),
    );
  }}
`;

export default withTheme(StyledGrid);
