import styled from 'styled-components';
import PropTypes from 'prop-types';
import { styledComponentsResponsiveStyles } from '../utils/responsive';
import withTheme from '../Theme/withTheme';
import gridItemPropsToCss from './props-to-css';
import renderByCloning from '../utils/renderByCloning';

const GridItem = ({
  alignSelf,
  area,
  children,
  columnEnd,
  columnStart,
  component,
  justifySelf,
  responsive,
  rowEnd,
  rowStart,
  uiTheme,
  ...otherProps
}) => renderByCloning(component, children, { ...otherProps });

GridItem.propTypes = {
  alignSelf: PropTypes.string,
  area: PropTypes.string,
  children: PropTypes.any,
  columnEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  columnStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  component: PropTypes.string,
  justifySelf: PropTypes.string,
  responsive: PropTypes.arrayOf(
    PropTypes.shape({
      minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      props: PropTypes.object,
    }),
  ),
  rowEnd: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  rowStart: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  style: PropTypes.shape(),
  uiTheme: PropTypes.object,
};

GridItem.defaultProps = {
  alignSelf: null,
  area: null,
  children: null,
  columnEnd: null,
  columnStart: null,
  component: null,
  justifySelf: null,
  responsive: [],
  rowEnd: null,
  rowStart: null,
  style: {},
  uiTheme: {},
};

const StyledGridItem = styled(GridItem)`
  ${props => gridItemPropsToCss(props)}
  ${props => {
    const { responsive, uiTheme } = props;
    return styledComponentsResponsiveStyles(uiTheme, responsive, breakpointProps =>
      gridItemPropsToCss({
        uiTheme,
        ...breakpointProps,
      }),
    );
  }}
`;

export default withTheme(StyledGridItem);
