import React from "react"
import FormSection from "../BookingForm/FormSection"
import Grid from "../ui/Grid"
import SelectBox from "../SelectBox"

const typesOfPremises = [
  {
    id: "office-block",
    value: "Office Block",
  },
  {
    id: "factory",
    value: "Factory",
  },
  {
    id: "retail-shop",
    value: "Retail Shop",
  },
  {
    id: "hotel",
    value: "Hotel",
  },
  {
    id: "multi-hmo",
    value: "MultiHMO",
  },
  {
    id: "other",
    value: "Other",
  },
]

function validate(values) {
  const errors = []

  if (!values.premisesType) {
    errors.push("Please select a premises type")
  }

  return errors
}

const PremisesType = ({ form, setForm, isEnd, onBack, onNext, visible }) => {
  const selectedPremisesType = form.values.premisesType

  return (
    <FormSection
      question="What type of Premises do you have?"
      description="Please let us know the type of premises that needs powerflushing."
      isEnd={isEnd}
      onBack={onBack}
      onNext={onNext}
      validate={validate}
      visible={visible}
      values={form.values}
      renderAnswers={() => (
        <Grid
          component="div"
          columns="1fr"
          responsive={[
            {
              minWidth: 900,
              props: {
                columns: "repeat(2, 1fr)",
                columnGap: "medium",
                rowGap: "medium",
              },
            },
          ]}
        >
          {typesOfPremises.map(({ id, value }) => {
            const checked = selectedPremisesType === id
            return (
              <SelectBox
                id={id}
                type="radio"
                checked={checked}
                onChange={() => {
                  setForm({
                    ...form,
                    values: { ...form.values, premisesType: id },
                  })
                }}
                input={{ value, name: "premisesType" }}
              >
                {value}
              </SelectBox>
            )
          })}
        </Grid>
      )}
    />
  )
}

export default PremisesType
